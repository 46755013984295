import React, { useState } from 'react';
import * as yup from 'yup';
import { TextField } from 'wui/TextField';
import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useFormik } from 'formik';

type IProps = {
  onClose: () => void;
  onCreate: (name: string) => void;
};

interface ICreateTopicForm {
  displayName: string;
}

export function CreateTopic({ onClose, onCreate }: IProps) {
  const { t } = useTranslation();
  const form = useFormik<ICreateTopicForm>({
    onSubmit: handleCreate,
    initialValues: {
      displayName: '',
    },
    validationSchema: yup.object().shape({
      displayName: yup
        .string()
        .trim()
        .required(
          t('groups-web.discussion.topics.modal.create-topic.input.error'),
        ),
    }),
  });

  return (
    <Box direction="vertical" verticalAlign="space-between" height="100%">
      <Box direction="vertical" gap="SP3">
        <Typography variant="p2-16">
          {t('groups-web.discussion.topics.modal.create-topic.title')}
        </Typography>
        <TextField
          label={t(
            'groups-web.discussion.topics.modal.create-topic.input.label',
          )}
          placeholder={t(
            'groups-web.discussion.topics.modal.create-topic.input.placeholder',
          )}
          maxLength={20}
          showCharCount
          {...form.getFieldMeta('displayName')}
          {...form.getFieldProps('displayName')}
        />
      </Box>
      <Box align="right" gap="SP4">
        <Button variant="basic" outlined onClick={onClose}>
          {t('groups-web.discussion.topics.modal.create-topic.cancel')}
        </Button>
        <Button onClick={form.submitForm} disabled={!form.isValid}>
          {t('groups-web.discussion.topics.modal.create-topic.create')}
        </Button>
      </Box>
    </Box>
  );

  function handleCreate(values: ICreateTopicForm) {
    onCreate(values.displayName);
  }
}

CreateTopic.displayName = 'CreateTopic';
