import React from 'react';
import cls from 'classnames';
import { take } from 'lodash';
import { useSelector } from 'react-redux';

import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Tooltip } from 'wui/Tooltip';
import { Typography } from 'wui/Typography';
import { ToggleButton, IToggleButtonProps } from 'wui/ToggleButton';

import { Emoji } from '../Emoji';
import { selectReactionsMap } from '../../ReactedMembers/selectors';

import classes from './Reaction.scss';

export interface IReactionProps extends Partial<IToggleButtonProps> {
  code: string;
  reacted: boolean;
  feedItemId: string;
  icon?: React.ReactNode;

  onClick(): void;
}

export const Reaction: React.FC<IReactionProps> = (props) => {
  const {
    code,
    icon,
    onClick,
    reacted,
    className,
    feedItemId,
    'data-hook': dataHook,
    ...rest
  } = props;

  const reactions = useSelector(selectReactionsMap(feedItemId));

  const summary = reactions[code] || [];

  return (
    <Tooltip
      data-hook={dataHook}
      disabled={!summary.length}
      content={
        <Stack gap="SP1" direction="vertical">
          {take(summary, 20).map((reaction) => (
            <Typography noWrap variant="p2-12" key={reaction.user.memberId}>
              {reaction.user.name}
            </Typography>
          ))}
          <Show if={summary.length > 20}>
            <span>...</span>
          </Show>
        </Stack>
      }
    >
      <ToggleButton
        onClick={onClick}
        selected={reacted}
        aria-label={code + ' ' + summary.length}
        data-hook="reaction-toggle-button"
        className={cls(classes.root, className)}
        {...rest}
      >
        <Stack
          gap="SP0"
          align="center"
          verticalAlign="middle"
          separator={<span>&nbsp;</span>}
        >
          <Emoji data-hook="reaction-emoji">{icon || code}</Emoji>
          <span data-hook="reaction-counter">{summary.length}</span>
        </Stack>
      </ToggleButton>
    </Tooltip>
  );
};

Reaction.displayName = 'Reaction';
