import { curryRight, without } from 'lodash';
import { createSelector } from 'reselect';

import { selectReactions } from 'store/selectors';

export const selectReactionCodes = curryRight(
  createSelector(
    [selectReactions, (_, __, primary: string[]) => primary],
    (summary, primary) => {
      const reactions = summary?.reactions.map((reaction) => reaction.code);

      return [primary, without(reactions, ...primary)];
    },
  ),
  3,
);
