import React, { useEffect } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';

import { Show } from 'wui/Show';
import { Popover } from 'wui/Popover';
import { Typography } from 'wui/Typography';
import { Stack } from 'wui/Stack';
import { TextButton } from 'wui/TextButton';
import { Tooltip } from 'wui/Tooltip';
import { TooltipSkin } from 'wix-ui-tpa/cssVars';
import {
  Plus as PlusIcon,
  Tag as TagIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import type { FeedSettings } from '@wix/ambassador-feed-settings-v1-feed-settings/types';

import { Topic } from '../../Topic';

import { TopicsForm } from './TopicsForm';
import { TopicsDialog } from './TopicsDialog';
import classes from './TopicSelect.scss';

interface IProps extends React.ComponentProps<typeof TopicsForm> {
  feedSettings?: FeedSettings;
  error?: string;
}

export function TopicsSelect(props: IProps) {
  const { groupId, value, error, feedSettings } = props;

  const { t } = useTranslation();
  const { topics$ } = useController();
  const { isMobile } = useEnvironment();

  const [isOpen, setIsOpen] = React.useState(false);

  const preview = value.slice(0, 2);
  const rest = value.slice(2);

  useEffect(() => {
    if (isOpen) {
      topics$.fetch(groupId);
    }
  }, [groupId, isOpen]);

  return (
    <>
      <Popover
        minWidth={320}
        isOpen={!isMobile && isOpen}
        onClose={handleClose}
        appendTo="parent"
        data-hook="topics-select"
        placement="top-start"
        content={<TopicsForm height="300px" {...props} />}
      >
        {preview.length ? (
          <Stack wrap verticalAlign="middle" gap="SP2">
            <Typography as="span">
              <TagIcon />
            </Typography>
            {preview.map((topic) => (
              <Topic
                data-hook="topic-preview"
                active
                key={topic.id}
                onClick={handleOpen}
                topic={topic}
              />
            ))}
            <Show if={!!rest.length}>
              <Typography variant="p2-14" as="span">
                <TextButton onClick={handleOpen} variant="secondary">
                  + {rest.length}
                </TextButton>
              </Typography>
            </Show>
          </Stack>
        ) : (
          <Tooltip
            disabled={!error || !feedSettings?.topicRequired}
            placement="right"
            skin={TooltipSkin.Error}
            content={error}
          >
            <TextButton
              onClick={handleOpen}
              prefixIcon={<PlusIcon />}
              data-hook="topics-select-open-button"
              data-error={!!error}
              data-required={feedSettings?.topicRequired}
              variant="secondary"
              className={error ? classes.openButtonError : undefined}
            >
              {feedSettings?.topicRequired
                ? t('groups-web.discussion.topics.modal.open-button.required')
                : t('groups-web.discussion.topics.modal.open-button')}
            </TextButton>
          </Tooltip>
        )}
      </Popover>
      <TopicsDialog
        isOpen={isMobile && isOpen}
        onClose={handleClose}
        {...props}
      />
    </>
  );

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }
}

TopicsSelect.displayName = 'TopicsSelect';
