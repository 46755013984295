import React from 'react';

import { useRicosFormat } from 'common/utils/ricos';
import { RicosViewer } from 'common/components/Ricos/RicosViewer';

import type { IFeedItem } from 'api/feed/types';

import { CardContent, type ICardContentProps } from 'wui/CardContent';

import { GROUPS_APP_DEFINITION_ID } from '../../../../constants';
import { getAriaId } from '../a11y';

import { ActivityContent } from './ActivityContent';

interface IProps extends Partial<ICardContentProps> {
  item: IFeedItem;
  truncate?: boolean;
}

export function FeedItemContent(props: IProps) {
  const { item, truncate, ...rest } = props;

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const content = useRicosFormat(item.entity?.body?.content);

  if (item.activity) {
    return (
      <ActivityContent
        item={item}
        id={getAriaId(feedItemId, 'content')}
        {...rest}
      />
    );
  }

  if (!content) {
    return null;
  }

  return (
    <CardContent id={getAriaId(feedItemId, 'content')} as="div" {...rest}>
      <RicosViewer
        usage="FeedItem"
        postId={feedItemId}
        containerId={GROUPS_APP_DEFINITION_ID}
        groupId={groupId}
        truncate={truncate}
        content={content}
        contentId={item.feedItemId}
        data-hook="feed-item-ricos-viewer"
      />
    </CardContent>
  );
}

FeedItemContent.displayName = 'FeedItemContent';
