import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { EmptyState } from 'wui/EmptyState';
import { TextButton } from 'wui/TextButton';
import { Plus as PlusIcon } from '@wix/wix-ui-icons-common/on-stage';

type IProps = {
  onAdd: () => void;
  isAdmin: boolean;
};

export function TopicsEmptyState(props: IProps) {
  const { onAdd, isAdmin } = props;
  const { t } = useTranslation();
  return (
    <EmptyState
      variant="section"
      title={
        isAdmin
          ? t('groups-web.discussion.topics.modal.empty-state.admin.title')
          : t('groups-web.discussion.topics.modal.empty-state.member.title')
      }
      subtitle={
        isAdmin
          ? t('groups-web.discussion.topics.modal.empty-state.admin.subtitle')
          : t('groups-web.discussion.topics.modal.empty-state.member.subtitle')
      }
      action={
        isAdmin ? (
          <TextButton
            prefixIcon={<PlusIcon />}
            variant="secondary"
            onClick={onAdd}
          >
            {t('groups-web.discussion.topics.modal.empty-state.admin.action')}
          </TextButton>
        ) : undefined
      }
    />
  );
}

TopicsEmptyState.displayName = 'TopicsEmptyState';
