import _ from 'lodash';
import React from 'react';

import {
  type AvatarGroupSize,
  type AvatarGroupProps,
  AvatarGroup as WUTAvatarGroup,
} from 'wix-ui-tpa/cssVars';

interface IProps extends Omit<AvatarGroupProps, 'size'> {
  size: 'xLarge' | 'large' | 'medium' | 'small' | 'xSmall' | 'xxSmall';
}

export function AvatarGroup(props: IProps) {
  const { size, ...rest } = props;

  return <WUTAvatarGroup size={size as AvatarGroupSize} {...rest} />;
}

AvatarGroup.displayName = 'wui/AvatarGroup';
AvatarGroup.TextButton = WUTAvatarGroup.TextButton;
