import React from 'react';
import cls from 'classnames';

import type { ITopic } from 'api/topics/types';

import { Tag } from 'wui/Tag';

import classes from './Topic.scss';

interface ITopicProps
  extends Omit<React.ComponentProps<typeof Tag>, 'onClick' | 'onRemove'> {
  active?: boolean;
  wired?: boolean;
  topic: ITopic;
  withCounter?: boolean;

  onClick?: (topic: ITopic) => void;
  onRemove?: (topic: ITopic) => void;
}

export function Topic(props: ITopicProps) {
  const {
    onClick,
    onRemove,
    topic,
    active,
    wired,
    className,
    withCounter,
    ...rest
  } = props;

  return (
    <Tag
      size="small"
      skin={active ? 'solid' : 'light'}
      onClick={props.onClick ? handleClick : undefined}
      onRemove={props.onRemove ? handleRemove : undefined}
      data-active={active}
      className={cls(className, {
        [classes.wired]: wired,
      })}
      {...rest}
    >
      {!withCounter
        ? topic.displayName
        : `${topic.displayName} (${topic.count})`}
    </Tag>
  );

  function handleClick() {
    props.onClick?.(topic);
  }

  function handleRemove() {
    props.onRemove?.(topic);
  }
}

Topic.displayName = 'Topic';
