import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';
import { selectFeedItemStatus } from 'store/selectors';
import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';
import { Button } from 'wui/Button';

interface IProps extends React.ComponentProps<typeof AlertDialog> {
  item: IFeedItem;
}

export function DeleteFeedItemDialog(props: IProps) {
  const { item, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { feed$ } = useController();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const status = useSelector(selectFeedItemStatus(feedItemId));

  useDidUpdate(() => {
    if (!status.remove.pending && !status.remove.error) {
      props.onClose();
    }
  }, [status.remove.pending]);

  return (
    <AlertDialog
      {...rest}
      aria-labelledby="dp-dialog-title"
      aria-describedby="dp-dialog-content"
    >
      <DialogTitle
        alert
        id="dp-dialog-title"
        title={t('groups-web.discussion.delete-post-popup.title')}
      />
      <DialogContent alert>
        <DialogContentText alert id="dp-dialog-content">
          {t('groups-web.discussion.delete-post-popup.subtitle')}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          fullWidth={isMobile}
          onClick={props.onClose}
        >
          {t('groups-web.discussion.delete-post-popup.cancel')}
        </Button>
        <Button
          variant="basic"
          fullWidth={isMobile}
          loading={status.remove.pending}
          onClick={handleSubmit}
        >
          {t('groups-web.discussion.delete-post-popup.delete')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    feed$.remove(groupId, item.feedItemId as string);
  }
}

DeleteFeedItemDialog.displayName = 'DeleteFeedItemDialog';
