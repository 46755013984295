import React from 'react';
import cls from 'classnames';
import { take } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';

import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Tooltip } from 'wui/Tooltip';
import { CardContent } from 'wui/CardContent';
import { Typography } from 'wui/Typography';
import { TextButton } from 'wui/TextButton';
import { AvatarGroup } from 'wui/AvatarGroup';

import { selectHasReactions, selectReactedUsers } from './selectors';
import { ReactedMembersDialog } from './ReactedMembersDialog';

import feedItemClasses from '../FeedItem.scss';
import classes from './styles.scss';

interface IProps {
  item: IFeedItem;

  toggleComments?(): void;
}

export function ReactedMembers(props: IProps) {
  const { item, toggleComments } = props;

  const { t } = useTranslation();

  const feedItemId = item.feedItemId as string;

  const users = useSelector(selectReactedUsers(feedItemId));
  const hasReactions = useSelector(selectHasReactions(feedItemId));

  const [isModalOpened, setIsModalOpened] = React.useState(false);

  const previewProfiles = take(users, 20).map((user) => ({
    name: user.name,
    src: user.imageUrl,
  }));

  if (!hasReactions) {
    return null;
  }

  return (
    <CardContent
      direction="horizontal"
      align="space-between"
      verticalAlign="middle"
    >
      <Tooltip
        placement="top"
        content={
          <Stack direction="vertical">
            {previewProfiles.map((profile, index) => (
              <Typography noWrap variant="p2-12" key={index}>
                {profile.name}
              </Typography>
            ))}
            <Show if={previewProfiles.length < users.length}>
              <span>...</span>
            </Show>
          </Stack>
        }
      >
        <AvatarGroup
          size="xSmall"
          maxAmount={4}
          items={previewProfiles}
          className={cls(feedItemClasses.reactedMembers, classes.avatarGroup)}
        >
          <AvatarGroup.TextButton
            onClick={item.permissions?.canViewFullPost ? openDialog : undefined}
          >
            {t('groups-web.discussion.feed.reactions.total_icu', {
              count: item.reactions.total,
            })}
          </AvatarGroup.TextButton>
        </AvatarGroup>
      </Tooltip>

      <Typography variant="p2-14" className={feedItemClasses.totalComments}>
        <TextButton variant="secondary" onClick={toggleComments}>
          {t('groups-web.discussion.feed.total-comments_icu', {
            count: item.comments?.total || item.latestComments?.total || 0,
          })}
        </TextButton>
      </Typography>

      <ReactedMembersDialog
        item={item}
        isOpen={isModalOpened}
        onClose={closeDialog}
      />
    </CardContent>
  );

  function openDialog() {
    setIsModalOpened(true);
  }

  function closeDialog() {
    setIsModalOpened(false);
  }
}

ReactedMembers.displayName = 'ReactedMembers';
